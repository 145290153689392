import { Transform, Writable } from 'stream';
import { getBezierStepPoint, getBezierCurve, getCurveLength, distance } from './utils.mjs';

export class PointStream extends Transform {

  constructor({
    fakePressure = false,          // 伪压力值
    velocityFilterPressure = 0.7,  // 压力/速度值基准值
    density = 2,                   // 密度
    sources,                       // 数据来源 {width: 1920, height: 1080, p_min: 0, p_max: 2000}
    view,                          // 目标视图
    ...rest}) {
    super({objectMode: true, ...rest});
    this.v_p = velocityFilterPressure;
    this.d   = density,
    this.fp  = fakePressure;
    this._lastPoints = [];
    this._lastVelocity = 0;
    if (sources && view) {
      const source = {width: 1920, height: 1080, p_min: 0, p_max: 2000, ...sources};
      const target = {width: 720,  height: 540,  p_min: 0, p_max: 1,    ...view};
      this.scale = {
        h: target.width  / source.width,    // horizontal
        l: target.height / source.height,   // longitudinal
        p: (target.p_max - target.p_min) / (source.p_max - source.p_min)
      };
    }
  }

  _pushCurvePoints (p0, p1, p2, p3) {
    let curve;
    if (this.fp) {
      const newVelocity = this.v_p * p1.v + (1 - this.v_p) * this._lastVelocity;
      curve = getBezierCurve(
        p3,
        {...p2, p: 1 / (this._lastVelocity + 1)},
        {...p1, p: 1 / (newVelocity + 1)},
        p0)
      this._lastVelocity = newVelocity;
    } else {
      curve = getBezierCurve(p3, p2, p1, p0);
    }
    const steps = getCurveLength(curve) * this.d ||1;
    for (let i = 0; i < steps; i++) {
      this.push(getBezierStepPoint(i / steps, curve))
    }
  }

  _transform (chunk, encode, callback) {
    const {s, x, y, p, t} = chunk;
    const [p1, p2, p3 = p2] = this._lastPoints;
    const p0 = this.scale ? {
      x: this.scale.h * x,
      y: this.scale.l * y,
      p: p && (this.scale.p * p),
      t, s
    } : {x, y, p, t, s};

    switch(s) {
      case(0):
        this._lastPoints = [p0, p0];
        this._lastVelocity = 0;
        return callback(null);
      case(1):
        if (!p1 || p0.t === 0) return callback(null); // no point 1 (第一个点的 s 不是 0)
        p0.d = distance(p1, p0);
        p0.v = (p0.d && t) ? (p0.d / t) : 0;
        this._lastPoints = [p0, p1, p2, p3];
        if (p1.d) this._pushCurvePoints(p0, p1, p2, p3);
        return callback(null);
      case(2):
        if (!p1 || !p2) return callback(null);        // no point 1 & 2
        this._pushCurvePoints(p0, p1, p2, p3);
        this._pushCurvePoints(p0, p0, p1, p2);
        return callback(null);
      default:
        return callback(new TypeError('point event error, s should be 0 1 2, get: ' + s))
    }
  }
}

const Pi2 = 2 * Math.PI;

export class BrushStream extends Writable {

  constructor({
    canvas,                // canvas object
    color = 'black',       // 颜色
    thickness = 5,         // 笔粗细
    ...rest}) {
    if (!canvas) throw new Error('BrushStream required canvas');
    super({objectMode: true, ...rest});
    this.canvas    = canvas;
    this.color     = color;
    this.thickness = thickness;
    this._ctx      = null;
  }

  get ctx() {
    if (this._ctx) return this._ctx;
    const ctx = this._ctx = this.canvas.getContext('2d');
    ctx.strokeStyle= ctx.fillStyle = this.color;
    return this._ctx;
  }

  _dot ({x, y, p = 0, t, s}) {
    this.ctx.beginPath();
    p=Math.min(Math.max(.3,
      p+.1*+(p-.02)
      +.2*(p-.03)
      +.3*(p-.04)
      +.4*(p-.05)
      +.5*(p-.06)
      +.6*(p-.07)
      +.7*(p-.08)
      +.8*(p-.09)
      +.9*(p-.1)
      ),2);
    this.ctx.moveTo(x,y);
    this.ctx.lineTo(x+p,y+p);
    this.ctx.lineWidth=p*this.thickness;
    this.ctx.stroke();
    this.ctx.closePath();
  }

  _write (chunk, encode, done) {
    this._dot(chunk);
    done();
  }

  _writev (chunks, done) {
    this.ctx.beginPath();
    for (let {x, y, p = 0.5} of chunks) {
      this.ctx.arc(x, y, p * this.thickness, 0, 2 * Math.PI);
    }
    this.ctx.closePath();
    this.ctx.fill();

    done();
  }
}
