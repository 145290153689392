// @flow

// export const pythagorean: (number, number) => number = (l1, l2) => Math.sqrt((l1 * l1) + (l2 * l2));

export const distance: (Point, Point) => number = (a, b) => Math.hypot(a.x - b.x, a.y - b.y);

export function getControlPoints (s1: PointEvent, s2: PointEvent, s3: PointEvent): [Point, Point] {
  const m1 = { x: (s1.x + s2.x) / 2, y: (s1.y + s2.y) / 2 };
  const m2 = { x: (s2.x + s3.x) / 2, y: (s2.y + s3.y) / 2 };
  const l1 = s2.d || distance(s1, s2);
  const l2 = s3.d || distance(s2, s3);
  const dxm = m1.x - m2.x;
  const dym = m1.y - m2.y;
  const k = l2 / (l1 + l2) || 0;
  const cm = { x: m2.x + dxm * k, y: m2.y + dym * k };
  const tx = s2.x - cm.x;
  const ty = s2.y - cm.y;
  return [
    {x: m1.x + tx, y: m1.y + ty},
    {x: m2.x + tx, y: m2.y + ty}
  ];
}

export function getBezierCurve (p0: PointEvent, p1: PointEvent, p2: PointEvent, p3: PointEvent): BezierCurve {
  return {
    start: p1,
    end  : p2,
    c1   : getControlPoints(p0, p1, p2)[1],
    c2   : getControlPoints(p1, p2, p3)[0],
  }
}

export function getCoord (t: number, start: number, end: number, c1: number, c2: number): number {
  const u = 1 - t;
  return (start * u * u * u)
  + (3 * c1 * u * u * t)
  + (3 * c2 * u * t * t)
  + (end * t * t * t)
}

export function getBezierStepPoint (t: number, curve: BezierCurve): Point {
  const {start, c1, c2, end} = curve;
  return {
    x: getCoord(t, start.x, end.x, c1.x, c2.x),
    y: getCoord(t, start.y, end.y, c1.y, c2.y),
    p: start.p + t * t * t * (end.p - start.p),
  }
}

export function getCurveLength (curve: BezierCurve) {
  const steps = 10;
  let length = 0;
  let b = {x: 0, y: 0};
  for (let i = 0; i <= steps; i += 1) {
    const a = getBezierStepPoint(i / steps, curve);
    if (i > 0)
      length += distance(a, b);
    b = a;
  }
  return Math.floor(length);
}

export const isPointEq = (p1: Point, p2: Point) =>
  p1.x === p2.x && p1.y === p2.y;
