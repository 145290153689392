import { Transform } from 'stream';
import { throttle as _throttle } from 'lodash-es';

export class DOMPointerStream extends Transform {

  constructor({dom, throttle = 40, ...rest}) {
    super({objectMode: true, ...rest});
    this._addPointerListener(dom, throttle);
    this._isDown = false;
    this._lastTime = 0;
    this._getBoundXY = _throttle(() => this._getBounding(dom), throttle * 10);
  }

  _getBounding(dom) {
    const {top, left, x = top, y = left} = dom.getBoundingClientRect();
    return {x, y}
  }

  _transform(e, encode, callback) {
    const {x, y} = this._getBoundXY();
    const now = Date.now();
    const t = this._lastTime ? now - this._lastTime : 0;
    const s = e.type === 'pointerdown' ? 0 : e.type === 'pointermove' ? 1 : 2;

    if (s !== 0 && !this._isDown) return callback(null);

    this._lastTime = now;
    this._isDown = s !== 2;

    return callback(null, {
      x: e.clientX - x,
      y: e.clientY - y,
      p: e.pressure,
      s, t,
    });
  }

  _addPointerListener(dom, throttle) {
    dom.addEventListener('pointerup',   this.write.bind(this));
    dom.addEventListener('pointerdown', this.write.bind(this));
    dom.addEventListener('pointermove', _throttle(this.write.bind(this), throttle));
  }

}
